import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import PreviewIcon from '@mui/icons-material/Preview';

//
import registrationExampleFront from './Zulassungsbescheinigung-I.1.jpg';
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';
import NoPreview from './NoPreview';

// ----------------------------------------------------------------------

function hexToRgb(hex, a) {
  let c = null;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${a})`;
  }
  throw new Error('Bad Hex');
}

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: '40px 8px',
  borderRadius: '8px',
  transition: 'padding 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
  // backgroundColor: "rgb(244, 246, 248)",
  // border: `1px dashed rgba(145, 158, 171, 0.32)`,
  border: `1px solid ${theme.palette.primary.main}`,
  boxShadow: `${hexToRgb(
    theme.palette.primary.main,
    0.12
  )} 0px 0px 0px, ${hexToRgb(theme.palette.primary.main, 0.24)} 0px 0px 0px;`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleFile({
  error = false,
  file,
  helperText,
  sx,
  label,
  ...other
}) {
  const theme = useTheme();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections,
  } = useDropzone({
    multiple: false,
    accept: {
      'image/*': ['.heic', '.pdf'],
      'application/*': ['.pdf'],
    },
    ...other,
  });
  const registrationImage = registrationExampleFront;

  return (
    <Box sx={{ position: 'relative', width: '100%', ...sx }}>
      {/* <Box
        sx={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          cursor: 'pointer',
        }}
      >
        <NoMaxWidthTooltip
          placement='bottom-end'
          enterTouchDelay={0}
          disableFocusListener
          title={
            <Image
              backgroundSize={'contain !important'}
              alt='Beispiel'
              src={registrationImage}
              sx={{
                width: { xs: '100%', md: '500px' },
                objectFit: 'contain',
              }}
            />
          }
        >
          <Box
            sx={{
              display: 'flex',
              marginTop: '5px',
              marginRight: '5px',
              color: theme.palette.primary.main,
            }}
          >
            <Typography>Beispiel</Typography> <PreviewIcon />
          </Box>
        </NoMaxWidthTooltip>
      </Box> */}
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'rgb(255, 72, 66)',
            borderColor: 'rgb(255, 164, 141)',
            bgcolor: 'rgb(255, 231, 217)',
          }),
          ...(file && {
            padding: '12% 0',
            minHeight: '200px',
          }),
        }}
      >
        <input {...getInputProps()} />

        {file ? (
          <>
            {file.type === 'image/heic' ||
            file.type === 'image/tiff' ||
            file.type === 'application/pdf' ? (
              <NoPreview fileName={file.name} />
            ) : (
              <Image
                backgroundSize={'contain !important'}
                alt='file preview'
                src={typeof file === 'string' ? file : file.preview}
                sx={{
                  objectFit: 'contain',
                  top: 8,
                  left: 8,
                  borderRadius: 1,
                  position: 'absolute',
                  width: 'calc(100% - 16px)',
                  height: 'calc(100% - 16px)',
                }}
              />
            )}
          </>
        ) : (
          <BlockContent label={label} />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && (
        <RejectionFiles fileRejections={fileRejections} />
      )}

      {helperText && helperText}
    </Box>
  );
}
