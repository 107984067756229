import axios from 'axios';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { isMobile } from 'react-device-detect';
import { ProcessStepper } from './ProcessStepper';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'preact/hooks';
import { useForm } from 'react-hook-form';
import Resizer from 'react-image-file-resizer';
import useMediaQuery from '@mui/material/useMediaQuery';
import dayjs from 'dayjs';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import config from '../config';
import { ServiceUnavailablePage } from './ServiceUnavailablePage';
import ThgqpLogo from '../static/thg-quotenportal_logo.svg';
import GreenfactoryLogo from '../static/greenfactory_logo.svg';
import FormInvite from './../Form/FormInvite';
import ThemeColorPresets from '../theme/ThemeColorPresets';

// jsx components
import { Header } from './Header';
import { Footer } from './Footer';

// api.js

import {
  fetchPartner,
  fetchApiKeyFromInitToken,
  fetchCharityPartner,
} from './api';

// form steps
import { Intropage } from './Intropage';
import { FormStepOne } from './Step1';
import { FormStepTwo } from './Step2';
import { FormStepThree } from './Step3';

const resizeFile = (file) => {
  return new Promise((resolve) => {
    try {
      Resizer.imageFileResizer(
        file,
        1920,
        1920,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'file'
      );
    } catch (error) {
      console.error(error);
    }
  });
};

export const Form = ({
  dataformKey,
  dataformInitKey,
  directSubmit,
  thgInvite,
  externalID,
}) => {
  const [initByToken, setInitByToken] = useState(false);
  const theme = useTheme();

  // secured integration - IFrame or initby token
  const isIframe = window.self !== window.top;

  const securedIntegration = isIframe || initByToken;

  const [dataformToken, setDataformToken] = useState(dataformKey);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [partner, setPartner] = useState(null);
  const [dataformOptions, setDataformOptions] = useState(null);
  const [charityPartner, setCharityPartner] = useState([]);
  const [inviteEmail, setInviteEmail] = useState(null);
  const [inviteId, setInviteId] = useState(null);
  const [platformPartner, setPlatformPartner] = useState(null);
  const [serverDate, setServerDate] = useState(false);
  const [serviceNotAvailable, setServiceNotAvailable] = useState(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  const [availableSubmissionYears, setAvailableSubmissionYears] = useState([]);

  //Set initial step
  const [activeStep, setActiveStep] = useState(null);
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));

  const initFormState = {
    type: 'b2c',
    email: inviteEmail,
    salesTax: false,
    vehicles: [],
  };

  const [formState, setFormState] = useState(initFormState);
  const defaultValues = { vehicles: [] };

  const {
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    if (dataformToken) {
      fetchPartner(dataformToken, inviteId).then((data) => {
        setPartner(data.partner);
        setDataformOptions(data.partner.dataformOptions);
        setPlatformPartner(data.partner.platformPartner);
        setServerDate(data.date);
      });
    }
  }, [dataformToken]);

  useEffect(() => {
    if (partner && partner.dataformOptions.deactivated) {
      setServiceNotAvailable(true);
    }
    console.log('🚀 ~ useEffect ~ config:', config);

    if (partner && partner.name === 'THG-QUOTENPORTAL') {
      ReactGA.initialize(config.GA_MEASUREMENT_ID);

      const tagManagerArgs = {
        gtmId: config.GTAG_ID,
      };
      console.log('🚀 ~ useEffect ~ config.GTAG_ID:', config.GTAG_ID);
      console.log('INIT TAGMANAGER');
      TagManager.initialize(tagManagerArgs);
    }
  }, [partner]);

  useEffect(() => {
    setFormState({ ...formState, email: inviteEmail });
  }, [inviteEmail]);

  useEffect(() => {
    if (serverDate) {
      //rule for available submission year

      const actualDate = dayjs(serverDate);
      const actualYear = actualDate.year();
      const actualMonth = actualDate.month() + 1;

      // const actualMonth = 12 ;
      // const actualYear = 2022;

      const submissionYears = [];

      const conditionThisYear = actualMonth < 11;
      if (conditionThisYear) {
        submissionYears.push(actualYear.toString());
      }

      const conditionNextYear = actualMonth >= 11;

      if (conditionNextYear) {
        submissionYears.push((actualYear + 1).toString());
      }

      setAvailableSubmissionYears(submissionYears);
    }
  }, [serverDate]);

  useEffect(() => {
    if (dataformInitKey) {
      fetchApiKeyFromInitToken(dataformInitKey).then((data) => {
        setPartner(data.partner);
        setPlatformPartner(data.partner.platformPartner);
        //Invited to thg  - fixed email
        if (data.inviteEmail) {
          setInviteEmail(data.inviteEmail);
          setInviteId(data.inviteId);
        }

        setDataformOptions(data.partner.dataformOptions);
        if (!data.expired) {
          setDataformToken(data.token);
          setInitByToken(true);
        } else {
          setTokenExpired(true);
        }
        if (data.submitted) {
          setAlreadySubmitted(true);
        }
      });
    }
  }, [dataformInitKey]);

  useEffect(() => {
    if (partner && partner.showCharity) {
      fetchCharityPartner(dataformToken, partner).then((data) => {
        setCharityPartner(data);
      });
    }

    if (partner && partner.dataformOptions.showIntropage) {
      // Show intro page
      setActiveStep(-1);
    }
    if (partner && !partner.dataformOptions.showIntropage) {
      setActiveStep(0);
    }
  }, [partner]);

  const hasErrors = Object.keys(errors).length !== 0;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (resetForm) => {
    if (resetForm) {
      reset(initFormState);

      setFormState(initFormState);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const sendData = async () => {
    try {
      let data = formState;
      let submitAmount = 0;
      //resize before upload
      const resizedVehicles = [];
      for (const vehicle of data.vehicles) {
        submitAmount += Math.round(vehicle.payoutPrice);
        const tempVehicle = vehicle;
        const fileExtension = getFileExtension(vehicle.registration_image.name);

        if (
          fileExtension !== 'heic' &&
          fileExtension !== 'pdf' &&
          fileExtension !== 'tif' &&
          fileExtension !== 'tiff' &&
          fileExtension !== 'svg'
        ) {
          //  Resize
          const resizedRegistrationImage = await resizeFile(
            vehicle.registration_image
          );

          tempVehicle['registration_image'] = resizedRegistrationImage;
        }

        resizedVehicles.push(tempVehicle);
      }
      data['vehicles'] = resizedVehicles;

      //Send data to DB, validate
      if (!hasErrors) {
        document.getElementById('wrapper').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSubmitted(true);
        setLoading(true);

        const formData = new FormData();

        data['status'] = 'new';

        if (externalID) {
          data['externalId'] = externalID;
        }

        //Save as JSON string because array in Formdata

        for (let i = 0; i < data.vehicles.length; i += 1) {
          const actualVehicle = data.vehicles[i];

          const selectedCharity = data.vehicles[i].selectedCharity;
          const charityAmount = data.vehicles[i].charityAmount;

          if (selectedCharity && charityAmount) {
            const selectedCharityPartner = charityPartner.find(
              (cPartner) => cPartner.name === selectedCharity
            );

            // delete selectedCharityPartner.description;

            data.vehicles[i]['charityPartner'] = selectedCharityPartner;
          }

          //delete temp value
          // Remove not needed props
          delete data.vehicles[i]['payoutPrice'];
          delete data.vehicles[i]['vehiclePayoutPrice'];
          delete data.vehicles[i]['selectedPayoutmode'];
          delete data.vehicles[i]['payoutPrice'];
          delete data.vehicles[i]['images'];
          delete data.vehicles[i]['selectedCharity'];

          for (const name in data.vehicles[i]) {
            if (data.vehicles[i][name] instanceof File) {
              //  handle form files
              formData.append(name + '#' + i, data.vehicles[i][name]);
            }
          }
        }
        formData.append('vehicles', JSON.stringify(data.vehicles));

        // add partnerId from env file
        data['partnerId'] = partner.name;
        data['date'] = new Date();
        data['ak'] = dataformToken;
        // directSubmit flag
        data['directSubmit'] = directSubmit;

        //Set inviteId
        data['inviteId'] = inviteId;

        data['mobileSubmit'] = isMobile;

        // Mock image regocnigtion object

        //  Delete Checbox data because this is only for form validation
        delete data.terms;
        delete data.withdrawal;
        delete data.concession;
        delete data.vehicles;
        delete data.status;
        delete data.submissionYears;

        for (const name in data) {
          formData.append(name, data[name]);
        }
        const apiHost =
          platformPartner === 'Greenfactory'
            ? config.API_HOST_GREENFACTORY
            : config.API_HOST;
        const response = await axios.post(
          `${apiHost}/v1/dataform/submit`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${dataformToken}`,
            },
          }
        );

        if (response.status === 200) {
          //response is created item
          setErrorMessage(null);
          setLoading(false);
          if (partner && partner.name === 'THG-QUOTENPORTAL') {
            ReactGA.event({
              category: 'Dataform',
              action: 'submit',
              value: submitAmount, // optional, must be a number
            });

            if (process.env.PREACT_APP_ENV === 'production') {
              window.gtag('event', 'conversion', {
                send_to: 'AW-11409519731/VG1MCM_Z6_YYEPPovcAq',
                value: submitAmount,
                currency: 'EUR',
                transaction_id: '',
              });

              ReactGA.ga('event', 'conversion', {
                send_to: 'AW-11409519731/VG1MCM_Z6_YYEPPovcAq',
                value: submitAmount,
                currency: 'EUR',
              });
            }
          }
        } else {
          console.log('submit error');
          setLoading(false);
          setErrorMessage(
            'Es ist ein Fehler aufgetreteten. Bitte wenden Sie sich an den Support.'
          );
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setSubmitted(true);
      setErrorMessage(
        'Es ist ein Fehler aufgetreteten. Bitte wenden Sie sich an den Support.'
      );
    }
  };

  if (thgInvite && partner) {
    return (
      <ThemeColorPresets colorCode={partner.colorCode}>
        <Box
          sx={{
            '& .MuiFormControl-fullWidth': { marginBottom: 1 },
            display: 'flex',
            padding: { xs: '42px 20px 0px 20px', md: '42px 42px 0px 42px' },
            maxWidth: '1080px',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            margin: 'auto',
          }}
        >
          <FormInvite
            platformPartner={platformPartner}
            partner={partner}
            dataformToken={dataformToken}
          />
        </Box>
      </ThemeColorPresets>
    );
  }

  if (alreadySubmitted) {
    return (
      <ThemeColorPresets colorCode={partner.colorCode}>
        <Box
          sx={{
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <ErrorOutlineIcon color='error' fontSize='large' />
          <Typography variant='h5'>
            Dieser Einladungslink ist abgelaufen oder wurde schon verwendet.
            <br />
            Bitte lassen Sie sich für die THG-Quotenvermarktung erneut einladen.
          </Typography>
        </Box>
      </ThemeColorPresets>
    );
  }
  if (tokenExpired && partner) {
    return (
      <ThemeColorPresets colorCode={partner.colorCode}>
        <Box
          sx={{
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <ErrorOutlineIcon color='error' fontSize='large' />
          <Typography variant='h5'>
            Leider ist Ihre Sitzung ist abgelaufen. Bitte starten Sie den
            Prozess erneut.
          </Typography>
          <Button
            size='large'
            href={`${partner.returnUrl || 'https://' + partner.domain}`}
            sx={{ marginTop: '20px' }}
            variant='contained'
          >
            zurück
          </Button>
        </Box>
      </ThemeColorPresets>
    );
  }

  if (
    !partner ||
    !partner.payoutPrices ||
    !platformPartner ||
    !dataformOptions
  ) {
    return (
      <Box sx={{ minHeight: '500px' }}>
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    );
  }
  if (
    dataformOptions.onlySecureIntegration &&
    !securedIntegration &&
    process.env.PREACT_APP_ENV === 'production'
  ) {
    return (
      <Box
        sx={{
          minHeight: '500px',
          textAlign: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <ErrorOutlineIcon color='error' fontSize='large' />
        <Typography variant='h5'>Dieser Aufruf ist nicht gültig.</Typography>
      </Box>
    );
  }

  const platformPartnerLogo =
    platformPartner === 'Greenfactory' ? GreenfactoryLogo : ThgqpLogo;
  const webappHost =
    platformPartner === 'Greenfactory'
      ? config.WEBAPP_HOST_GREENFACTORY
      : config.WEBAPP_HOST;

  const webappStaticRoute =
    platformPartner === 'Greenfactory' ? 'greenfactory' : 'thgqp';
  let headlineYear = '';
  if (availableSubmissionYears.length === 1) {
    headlineYear = ` ${availableSubmissionYears[0]}`;
  }

  if (!activeStep && activeStep !== 0) {
    return (
      <Box sx={{ minHeight: '500px' }}>
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      </Box>
    );
  }

  return (
    <ThemeColorPresets colorCode={partner.colorCode}>
      <Box
        sx={{
          '& .MuiFormControl-fullWidth': { marginBottom: 1 },
          display: 'flex',
          padding: { xs: '0px 20px 0px 20px', md: '0px 42px 0px 42px' },
          maxWidth: '1080px',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          margin: 'auto',
        }}
      >
        <Card
          sx={{
            padding: '32px 48px',
            borderTop: 'none',
            borderRadius: '0xp 0px 4px 0px',
          }}
        >
          {dataformOptions.showHeader && (
            <Header
              webappHost={webappHost}
              partner={partner}
              securedIntegration={securedIntegration}
              headlineYear={headlineYear}
            />
          )}

          <Box
            className='content'
            sx={{
              margin: '0px auto',
              display: 'flex',
              flexDirection: 'column',
              // flexGrow: '1',
              width: '100%',
            }}
          >
            <>
              {serviceNotAvailable ? (
                <ServiceUnavailablePage actualYear={'2025'} partner={partner} />
              ) : (
                <>
                  {activeStep >= 0 && (
                    <>
                      <ProcessStepper
                        activeStep={activeStep}
                        isMobileView={isMobileView}
                      />
                    </>
                  )}

                  {loading ? (
                    <Loading />
                  ) : (
                    <Box sx={{ minHeight: '500px' }}>
                      <LoadedComponent
                        handleBack={handleBack}
                        handleNext={handleNext}
                        sendData={sendData}
                        activeStep={activeStep}
                        submitted={submitted}
                        errorMessage={errorMessage}
                        directSubmit={directSubmit}
                        formState={formState}
                        availableSubmissionYears={availableSubmissionYears}
                        securedIntegration={securedIntegration}
                        isIframe={isIframe}
                        serverDate={serverDate}
                        dataformOptions={dataformOptions}
                        setFormState={setFormState}
                        partner={partner}
                        charityPartner={charityPartner}
                        inviteEmail={inviteEmail}
                        headlineYear={headlineYear}
                        isMobileView={isMobileView}
                        serviceNotAvailable={serviceNotAvailable}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          </Box>
        </Card>
        {dataformOptions.showFooter && (
          <Footer
            platformPartnerLogo={platformPartnerLogo}
            webappHost={webappHost}
            webappStaticRoute={webappStaticRoute}
          />
        )}
      </Box>
    </ThemeColorPresets>
  );
};

const LoadedComponent = ({
  sendData,
  activeStep,
  submitted,
  errorMessage,
  directSubmit,
  formState,
  handleBack,
  handleNext,
  availableSubmissionYears,
  securedIntegration,
  isIframe,
  serverDate,
  dataformOptions,
  setFormState,
  partner,
  charityPartner,
  inviteEmail,
  headlineYear,
  isMobileView,
  serviceNotAvailable,
}) => {
  return (
    <>
      {submitted ? (
        <Box>
          {errorMessage ? (
            <Box sx={{ margin: 'auto', textAlign: 'center', mt: 4 }}>
              <CancelOutlinedIcon
                color='error'
                sx={{
                  fontSize: '45px',
                  display: 'block',
                  marginBottom: '20px',
                  margin: 'auto',
                }}
              />
              <Typography>Es ist ein Fehler aufgetreten.</Typography>
            </Box>
          ) : (
            <Box sx={{ margin: 'auto', textAlign: 'center' }}>
              <ForwardToInboxIcon
                sx={{
                  fontSize: '45px',
                  display: 'block',
                  marginBottom: '20px',
                  margin: 'auto',
                }}
              />

              <Typography sx={{ marginTop: '10px', fontWeight: '700' }}>
                {directSubmit ? (
                  <>
                    <Typography sx={{ marginTop: '10px' }}>
                      Vielen Dank für die Übermittlung der Daten.
                    </Typography>

                    <span>
                      Der Empfänger {formState.email} erhält nun eine E-Mail,
                      aus der er die Vermarktung der THG-Quote starten kann.
                    </span>
                  </>
                ) : (
                  <>
                    <Typography sx={{ marginTop: '10px', fontWeight: 700 }}>
                      Fast geschafft!
                      <br />
                      Bitte bestätigen Sie Ihrer E-Mail Adresse, um die
                      Vermarktung zu starten!
                    </Typography>

                    <span>
                      <Typography sx={{ marginTop: '10px' }}>
                        <Box sx={{}}>
                          Hierzu wurde Ihnen eine Nachricht mit einem
                          Bestätigungslink an das Postfach
                          <strong>{` ${formState.email} `}</strong>geschickt.
                          <br />
                          Bitte bestätigen Sie Ihre E-Mail-Adresse innerhalb von{' '}
                          <strong>24 Stunden</strong>.
                        </Box>
                        <Box sx={{ marginTop: '10px' }}>
                          <strong>Hinweis:</strong> Sollten Sie keine Nachricht
                          erhalten haben, überprüfen Sie bitte Ihren
                          SPAM-Ordner.
                        </Box>
                      </Typography>
                    </span>
                  </>
                )}
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <>
          <>
            {activeStep === -1 && (
              <Intropage handleNext={handleNext} partner={partner} />
            )}

            {activeStep === 0 && (
              <FormStepOne
                availableSubmissionYears={availableSubmissionYears}
                isIframe={isIframe}
                serverDate={serverDate}
                dataformOptions={dataformOptions}
                setFormState={setFormState}
                formState={formState}
                handleNext={handleNext}
                partner={partner}
                directSubmit={directSubmit}
                charityPartner={charityPartner}
              />
            )}
            {activeStep === 1 && (
              <FormStepTwo
                inviteEmail={inviteEmail}
                setFormState={setFormState}
                formState={formState}
                handleNext={handleNext}
                handleBack={handleBack}
                partner={partner}
              />
            )}
            {activeStep === 2 && (
              <FormStepThree
                inviteEmail={inviteEmail}
                setFormState={setFormState}
                formState={formState}
                directSubmit={directSubmit}
                handleBack={handleBack}
                sendData={sendData}
                partner={partner}
              />
            )}
          </>
        </>
      )}
    </>
  );
};

const Loading = () => {
  return (
    <Box
      sx={{ display: 'block', textAlign: 'center', mt: 8, minHeight: '500px' }}
    >
      <CircularProgress />
    </Box>
  );
};

export default Form;
