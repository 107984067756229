import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Alert, Box, TextField, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import { CustomPhoneInput } from './CustomPhoneInput';
import { SubHeadline } from '../../components/SubHeadline';

export const ContactInformationForm = ({
  handleProcessTypeChange,
  formState,
  control,
  handleSalesTaxChange,
  errors,
  dirtyFields,
  formValues,
  successFields,
  inviteEmail,
  phoneFieldStyle,
  suggestion,
}) => {
  return (
    <>
      <Box sx={{ mb: 1, display: 'flex' }}>
        <SubHeadline text='Kontaktdaten' />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Box
            sx={{
              marginBottom: '12px',
            }}
          >
            <Controller
              render={({ field }) => (
                <FormControl>
                  <RadioGroup
                    {...field}
                    row
                    aria-labelledby='type'
                    name='type'
                    onChange={handleProcessTypeChange}
                    value={formState.type}
                  >
                    <FormControlLabel
                      value='b2c'
                      control={<Radio />}
                      label='Privatperson'
                    />
                    <FormControlLabel
                      value='b2b'
                      control={<Radio />}
                      label='Unternehmen'
                    />
                  </RadioGroup>
                </FormControl>
              )}
              name='type'
              control={control}
            />
          </Box>
        </Grid>
        {formState.type === 'b2b' && (
          <>
            <Grid item xs={12} md={12}>
              <Box sx={{ marginBottom: '16px' }}>
                <Controller
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel id='salesTax'>
                        Sind Sie umsatzsteuerpflichtig?
                      </FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        aria-labelledby='salesTax'
                        name='salesTax'
                        onChange={handleSalesTaxChange}
                        value={formState.salesTax}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label='Ja'
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label='Nein'
                        />
                      </RadioGroup>
                    </FormControl>
                  )}
                  name='salesTax'
                  control={control}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                render={({ field }) => (
                  <TextField
                    {...field}
                    id='companyName'
                    InputProps={{
                      endAdornment: (
                        <>
                          {errors &&
                            Object.keys(dirtyFields).includes('companyName') &&
                            formValues.companyName && (
                              <>
                                <InputAdornment
                                  sx={{ marginRight: '20px' }}
                                  position='end'
                                >
                                  {errors.companyName ? (
                                    <ErrorIcon color='error' />
                                  ) : (
                                    <CheckIcon color='success' />
                                  )}
                                </InputAdornment>
                              </>
                            )}
                        </>
                      ),
                    }}
                    label={'Unternehmensname inkl. Rechtsform'}
                    variant='outlined'
                    fullWidth
                    error={!!errors.companyName}
                  />
                )}
                rules={{
                  required: true,
                }}
                name='companyName'
                control={control}
                placeholder='Firmenname'
              />
            </Grid>
            {formState.salesTax ? (
              <Grid item xs={12} md={6}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      id='taxId'
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <>
                            {errors &&
                              (Object.keys(dirtyFields).includes('taxId') ||
                                successFields.includes('taxId')) &&
                              formValues.taxId && (
                                <>
                                  <InputAdornment
                                    sx={{ marginRight: '20px' }}
                                    position='end'
                                  >
                                    {errors.taxId ? (
                                      <ErrorIcon color='error' />
                                    ) : (
                                      <CheckIcon color='success' />
                                    )}
                                  </InputAdornment>
                                </>
                              )}
                          </>
                        ),
                      }}
                      label={'Umsatzsteuer-ID oder Steuernummer:'}
                      variant='outlined'
                      fullWidth
                      error={!!errors.taxId}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                  name='taxId'
                  control={control}
                  placeholder='Umsatzsteuer-ID oder Steuernummer:'
                />
              </Grid>
            ) : (
              <Grid item xs={12} md={6} />
            )}
          </>
        )}
        <Grid item xs={12} md={2}>
          <Controller
            name='salutation'
            rules={{
              required: true,
            }}
            render={({ field, field: { ref } }) => {
              return (
                <FormControl
                  id='salutation'
                  fullWidth
                  error={!!errors.salutation}
                >
                  <InputLabel id='select-label'>Anrede</InputLabel>

                  <Select
                    ref={ref}
                    {...field}
                    endAdornment={
                      <>
                        {errors && formValues.salutation && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {errors.salutation ? (
                                <ErrorIcon color='error' />
                              ) : (
                                <CheckIcon color='success' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                      </>
                    }
                    labelId='select-label'
                    id='select'
                    label='Anrede'
                  >
                    <MenuItem value={'mr'}>Herr</MenuItem>
                    <MenuItem value={'mrs'}>Frau</MenuItem>
                    <MenuItem value={'diverse'}>Divers</MenuItem>
                  </Select>
                </FormControl>
              );
            }}
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={10} />
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field, field: { ref } }) => (
              <TextField
                id='firstName'
                ref={ref}
                {...field}
                InputProps={{
                  endAdornment: (
                    <>
                      {errors &&
                        Object.keys(dirtyFields).includes('firstName') &&
                        formValues.firstName && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {errors.firstName ? (
                                <ErrorIcon color='error' />
                              ) : (
                                <CheckIcon color='success' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                    </>
                  ),
                }}
                label={'Vorname'}
                variant='outlined'
                fullWidth
                error={!!errors.firstName}
              />
            )}
            rules={{
              required: true,
            }}
            name='firstName'
            control={control}
            placeholder='Vorname'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <>
                      {errors &&
                        Object.keys(dirtyFields).includes('lastName') &&
                        formValues.lastName && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {errors.lastName ? (
                                <ErrorIcon color='error' />
                              ) : (
                                <CheckIcon color='success' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                    </>
                  ),
                }}
                id='lastName'
                label={'Nachname'}
                variant='outlined'
                fullWidth
                error={!!errors.lastName}
              />
            )}
            rules={{
              required: true,
            }}
            name='lastName'
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <>
                      {errors &&
                        Object.keys(dirtyFields).includes('street') &&
                        formValues.street && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {errors.street ? (
                                <ErrorIcon color='error' />
                              ) : (
                                <CheckIcon color='success' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                    </>
                  ),
                }}
                id='street'
                label={'Strasse'}
                variant='outlined'
                fullWidth
                error={!!errors.street}
              />
            )}
            rules={{
              required: true,
            }}
            name='street'
            control={control}
          />{' '}
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  endAdornment: (
                    <>
                      {errors &&
                        Object.keys(dirtyFields).includes('housenumber') &&
                        formValues.housenumber && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {errors.housenumber ? (
                                <ErrorIcon color='error' />
                              ) : (
                                <CheckIcon color='success' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                    </>
                  ),
                }}
                id='housenumber'
                label={'Hausnummer'}
                variant='outlined'
                fullWidth
                error={!!errors.housenumber}
              />
            )}
            rules={{
              required: true,
            }}
            name='housenumber'
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <TextField
                id='zip'
                {...field}
                InputProps={{
                  endAdornment: (
                    <>
                      {errors &&
                        Object.keys(dirtyFields).includes('zip') &&
                        formValues.zip &&
                        formValues.zip.length >= 5 && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {/^[0-9]{5}$/.test(formValues.zip) ? (
                                <CheckIcon color='success' />
                              ) : (
                                <ErrorIcon color='error' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                    </>
                  ),
                }}
                label={'Postleitzahl'}
                variant='outlined'
                fullWidth
                error={!!errors.zip}
              />
            )}
            rules={{
              required: true,
              pattern: /^[0-9]{5}$/,
            }}
            name='zip'
            control={control}
          />{' '}
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <TextField
                id='city'
                {...field}
                InputProps={{
                  endAdornment: (
                    <>
                      {errors &&
                        Object.keys(dirtyFields).includes('city') &&
                        formValues.city && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {errors.city ? (
                                <ErrorIcon color='error' />
                              ) : (
                                <CheckIcon color='success' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                    </>
                  ),
                }}
                label={'Stadt'}
                variant='outlined'
                fullWidth
                error={!!errors.city}
              />
            )}
            rules={{
              required: true,
            }}
            name='city'
            control={control}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            render={({ field }) => (
              <TextField
                id='email'
                {...field}
                InputProps={{
                  endAdornment: (
                    <>
                      {errors &&
                        Object.keys(dirtyFields).includes('email') &&
                        formValues.email &&
                        formValues.email.length >= 5 && (
                          <>
                            <InputAdornment
                              sx={{ marginRight: '20px' }}
                              position='end'
                            >
                              {/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                                formValues.email
                              ) ? (
                                <CheckIcon color='success' />
                              ) : (
                                <ErrorIcon color='error' />
                              )}
                            </InputAdornment>
                          </>
                        )}
                    </>
                  ),
                }}
                label={'Email'}
                variant='outlined'
                fullWidth
                disabled={inviteEmail}
                error={!!errors.email}
              />
            )}
            rules={{
              required: true,
              pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            }}
            name='email'
            control={control}
          />
          {suggestion && !errors.email ? (
            <Alert severity='warning' sx={{ mb: 3, marginTop: '10px' }}>
              Meinen Sie vielleicht {suggestion.full}?
            </Alert>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={phoneFieldStyle}>
            <Controller
              render={({ field }) => (
                <CustomPhoneInput
                  {...field}
                  error={errors.phone}
                  InputProps={{
                    endAdornment: (
                      <>
                        {errors &&
                          Object.keys(dirtyFields).includes('phone') &&
                          formValues.phone && (
                            <>
                              <InputAdornment
                                sx={{ marginRight: '20px' }}
                                position='end'
                              >
                                {errors.phone ? (
                                  <ErrorIcon color='error' />
                                ) : (
                                  <CheckIcon color='success' />
                                )}
                              </InputAdornment>
                            </>
                          )}
                      </>
                    ),
                  }}
                />
              )}
              rules={{
                required: true,
              }}
              name='phone'
              id='phone'
              control={control}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
