import { Grid, Typography, Box } from '@mui/material';
import { NoPreview } from '../../components/upload';
import useMediaQuery from '@mui/material/useMediaQuery';
import ThankYouImage from '../../static/ThankYou.svg';
import { fCurrencyEuro } from '../../utils/formatNumber';
import { SubHeadline } from '../../components/SubHeadline';

export const VehicleSummary = ({ formState, partner, theme, formValues }) => {
  const isMobileView = !useMediaQuery(theme.breakpoints.up('md'));
  const equalPayout = partner.dataformOptions?.equalPayout;

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toLowerCase();
  };

  const labelMapping = {
    M1: 'E-PKW',
    LXe: 'E-Zweirad',
    N1: 'E-Nutzfahrzeug',
    N2: 'E-Nutzfahrzeug',
    N3: 'E-Nutzfahrzeug',
    M3: 'E-Bus',
  };

  const subLabelMapping = {
    M1: 'M1',
    LXe: 'L3e, L4e, L5e, L7e',
    N1: 'N1 bis 3,5t',
    N2: 'N2 ab 3,5t bis 12t',
    N3: 'N3 ab 12t',
    M3: 'M3',
  };

  return (
    <Grid item xs={12} md={12} sx={{ pt: '32px!important' }}>
      {' '}
      <Box sx={{ mb: 1, display: 'flex' }}>
        <SubHeadline
          text={`${formState.vehicles.length === 1 ? 'Fahrzeug' : 'Fahrzeuge'}`}
        />
      </Box>
      {formState.vehicles.length > 0 && (
        <Box sx={{ marginTop: '40px', marginBottom: '40px' }}>
          <Box sx={{ mt: 8 }}>
            {formState.vehicles.map((vehicle, index) => {
              return (
                <Grid
                  container
                  spacing={5}
                  justifyContent={{ xs: 'center', md: 'space-between' }}
                  sx={{
                    textAlign: { xs: 'center', md: 'left' },
                    overflow: 'hidden',
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                    borderRadius: '4px',
                    boxShadow: 'none',
                    marginBottom: '48px',

                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ paddingTop: '16px!important', pb: 2 }}
                  >
                    <Box
                      aria-controls='panel1a-content'
                      id='panel1a-header'
                      sx={{ paddingRight: '20px' }}
                    >
                      <Box>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>Fahrzeugklasse:</strong>{' '}
                          {`${labelMapping[vehicle.vehicleClass]} (${
                            subLabelMapping[vehicle.vehicleClass]
                          })`}
                        </Typography>

                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`THG-Quotenprämie: `}</strong>

                          {fCurrencyEuro(vehicle.vehiclePayoutPrice)}
                        </Typography>
                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Abrechnungszeitraum: `}</strong>

                          {`${vehicle.submissionYears.join(', ')}`}
                        </Typography>

                        <Typography sx={{ marginLeft: '10px' }}>
                          <strong>{`Auszahlungstyp: `}</strong>{' '}
                          {vehicle.payoutMode === 'instant'
                            ? 'Sofortauszahlung'
                            : 'Auszahlung nach Bestätigung durch Umweltbundesamt'}
                        </Typography>

                        {vehicle.charityAmount ? (
                          <Box>
                            <Typography sx={{ marginLeft: '10px' }}>
                              <strong>{`Auszahlungsbetrag: `}</strong>{' '}
                              {fCurrencyEuro(
                                vehicle.vehiclePayoutPrice -
                                  vehicle.charityAmount
                              )}
                            </Typography>
                            <Typography sx={{ marginLeft: '10px' }}>
                              <strong>{`Spendenbetrag: `}</strong>{' '}
                              {fCurrencyEuro(vehicle.charityAmount)}
                            </Typography>
                          </Box>
                        ) : (
                          <></>
                        )}
                        {vehicle.selectedCharity &&
                        vehicle.selectedCharity !== 'Keine Spende' ? (
                          <Typography sx={{ marginLeft: '10px' }}>
                            <strong>{`Spendenpartner: `}</strong>{' '}
                            {vehicle.selectedCharity}
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{ paddingTop: '16px!important', pb: 2 }}
                  >
                    <Box sx={{ paddingRight: '32px' }}>
                      {vehicle.registration_image && (
                        <Grid item xs={12} md={12}>
                          {getFileExtension(vehicle.registration_image.name) ===
                            'heic' ||
                          getFileExtension(vehicle.registration_image.name) ===
                            'pdf' ? (
                            <NoPreview
                              fileName={vehicle.registration_image.name}
                            />
                          ) : (
                            <img
                              src={`${window.URL.createObjectURL(
                                vehicle.registration_image
                              )}`}
                              style='width: 100%; object-fit: contain; max-height: 300px'
                            />
                          )}
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Box>
      )}
    </Grid>
  );
};
