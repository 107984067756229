import { Box, Grid } from '@mui/material';
import { SubHeadline } from '../../components/SubHeadline';
import { StepsExplainItem } from './StepsExplainItem';

const steps = [
  'Übermitteln Sie Ihren Fahrzeugschein und Ihre Kontaktdaten.',
  'Starten Sie die Vermarktung mit der Bestätigung Ihrer Email-Adresse.',
  'Auszahlung in 3 - 5 Tagen direkt auf Ihr Konto.',
];

export const StepsExplain = ({ headline }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 4, mt: 4, p: 4 }}>
      <SubHeadline text={headline} />

      <Grid container spacing={2}>
        {steps.map((stepText, index) => (
          <Grid item xs={12} md={12 / steps.length} sx={{}}>
            <StepsExplainItem
              stepNumber={index + 1}
              text={stepText}
            ></StepsExplainItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
